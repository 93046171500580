import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { UserRoleMenuTabNameModel } from '../../models/user-role-menu-tab-name/user-role-menu-tab-name.model';
import { CurrentUserPermissionsService } from '../../services/current-user-permissions/current-user-permissions.service';

@Directive({
  selector: '[appUserHasPermissionForMenuTab]',
})
export class UserHasPermissionForMenuTabDirective implements OnInit {
  @Input() public appUserHasPermissionForMenuTab!: UserRoleMenuTabNameModel;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private currentUserPermissionsService: CurrentUserPermissionsService
  ) {}

  public ngOnInit(): void {
    this.applyPermission();
  }

  private applyPermission(): void {
    const isUserEntitled: boolean = this.currentUserPermissionsService.getShowValueForMenuTabName(this.appUserHasPermissionForMenuTab);

    if (isUserEntitled) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
